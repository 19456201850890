<script>

import {mapGetters} from "vuex";

export default {
  name: "AppLoading",
  computed: {
    ...mapGetters(['isShowAppLoading']),
    isShow(){
      return this.isShowAppLoading;
    },
  }
}
</script>

<template>
  <div style="position:fixed;width:100%;height:100vh;background: rgba(0, 0, 0, 0.5);z-index: 999;" :style="{display: isShow ? 'block' : 'none'}">
    <div style="position:absolute;top:30dvh;left:0;width:100%;">
      <div style="margin-top:50px;text-align:center;line-height:0;">
        <div><img src="/img/loading/loading_bar.svg" width="250" style="line-height:0;"></div>
      </div>
    </div>
  </div>
</template>